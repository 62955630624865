<template>
	<div class="Dispole">
		<div class="ban_box" :style="{'background-image': 'url('+rz_s_banner+')'}">
			<div class="widthMin banner_cont">
				<div class="banner_cont_title">
					<h2 class="text-center mb-2 pt-md-5">拆迁设备处置 我们最专业</h2>
				    <h4 class="text-center mb-5">Make Finance</h4>
				</div>
				<div class="banner_cont_info">
					<ul>
						<li>
							<span>11年</span>
							<div>
								<p>专注回收领域</p>
								<p>集团11年间，始终专注于二手设备及循环再生行业，不断完善超越自己</p>
							</div>
						</li>
						<li>
							<span>208人</span>
							<div>
								<p>专业服务团队</p>
								<p>集团有近280人的专业服务团队，为您处置好每一个设备</p>
							</div>
						</li>
						<li>
							<span>6,000名</span>
							<div>
								<p>战略合作客户</p>
								<p>集团现有战略合作客户，6000余名，快速回收您的每一个资产</p>
							</div>
						</li>
						<li>
							<span>300,000人</span>
							<div>
								<p>海量客户群体</p>
								<p>集团有累计30万+的海量回收用户，能帮您更佳精准的匹配好每一次交易</p>
							</div>
						</li>
					</ul>
					<!--我们的领域-->
					<div class="b_m_l">
						<h2>我们的领域</h2>
						<h4>OUR FIELD</h4>
						<p>回收再生，让每一个设备和废料发挥它应有的价值</p>
						<p>是我们一致以来并称的集团宗旨</p>
						<p>帮客户处理好每一笔交易，获得更高的溢价</p>
						<p>是我们的追求</p>
						<p>快速匹配到精准的线上及线下需求</p>
						<p>是我们的本分</p>
					</div>
				</div>
			</div>
		</div>
		<div class="warp widthMin">
			<div class="m_z mb-3">
				<div class="m_z_b_title">
					<button>
						<img src="../../assets/images/web_h5/m_2.png" />
						<span>现在去融资</span>
					</button>
				</div>
				<div class="pt-md-5 m_z_tip">简单填写项目资料、即可完成申请</div>
			</div>
			<!--我们提供的处置服务-->
			<div class="m_z mb-3 m_b">
				<div class="m_z_title">
					<h2 class="text-center mb-2 pt-md-5">我们提供的处置服务</h2>
				    <h4 class="text-center mb-5">Service Project</h4>
				</div>
				<div class="m_b_btns">
					<button>预售拍卖会</button>
					<button>O2O引流服务</button>
					<button>定价·大数据分析</button>
					<button>需求匹配·大数据</button>
				</div>
				<div class="m_b_btns mt-2">
					<button>战略合作伙伴 交易撮合</button>
					<button>还款金保全服务</button>
					<button>基于LBS的资产管理</button>
					<button>资金及账目管理服务</button>
				</div>
				<div class="m_b_info pt-md-5">
					<div class="m_b_info_l">
						<img class="h_img" :src="rz_wx" />
					</div>
					
					<div class="m_b_info_r" style="align-items:flex-start;padding-top: 10px;">
						<div class="info_box">
							<h2>预售拍卖会</h2>
							<h3>在拆迁前就帮您卖出</h3>
							<div>在您拆迁进场前，一旦您中标拆迁项目，在开始动工前。我们就会组织专业的线上拍卖会，通过数据邀请有购买意向的回收客户参与，价高者得。获得更好溢价空间得同时，加倍提升您得周转效率，让您设备前脚拆下来，后脚就直接拉走。</div>
						</div>
					</div>
				</div>
				
				<div class="m_z_b_title ">
					<button>
						<img src="../../assets/images/web_h5/m_2.png" />
						<span>查看数据演示</span>
					</button>
				</div>
				<div class="pt-md-5 m_z_tip">简单填写项目资料、即可完成申请</div>
			</div>
			
			<!--处置平台服务端-->
			<div class="m_z mb-3 m_b">
				<div class="m_z_title">
					<h2 class="text-center mb-2 pt-md-5">处置平台服务端</h2>
				    <h4 class="text-center mb-4">Service Project</h4>
				</div>
				<div class="m_z_btns">
					<button  @mouseenter="enter(1)">好多资产 小程序</button>
					<button  @mouseenter="enter(2)">好多资产 电脑端平台</button>
				</div>
				
				<div class="m_b_info pt-md-5">
					<div class="m_b_info_l" style="flex: 0 0 538px;">
						<img class="h_img" :src="src" />
					</div>
					
					<div class="m_b_info_r" style="align-items:flex-start;padding-top: 10px;">
						<div class="info_box service_roject">
							<h2 class="q_h2_text">处置小程序</h2>
							<div class="s_tips">
								<h3>线上拍卖会</h3>
								<div>竞买人可通过小程序参与线上拍卖会的报名、竞拍，并最终根据最高出价，价高者得</div>
							</div>
							<div>
								<h3>线上处置</h3>
								<div>回收用户可通过小程序直接购买相应的设备</div>
							</div>
							<div>
								<h3>现场扫码购买</h3>
								<div>平台邀请组织的相关看货会及看货人员在项目现场直接扫描拆迁设备上的二维码即可进行购买</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="m_z mb-3 m_b">
				<div class="m_z_title">
					<h2 class="text-center mb-2 pt-md-5">拆迁项目处置服务流程</h2>
				    <h4 class="text-center mb-4">Service Project</h4>
				</div>
				<div class="s_step pt-md-5">
					<div class="img_say">
						<div>
							<h2>2.内部沟通确定处置方案</h2>
							<span>根据融配资双方需要处置的抵押物进行人工评估及处置方案策划</span>
						</div>
						<div>
							<h2>4.大数据需求匹配</h2>
							<span>根据融配资双方需要处置的抵押物进行人工评估及处置方案策划</span>
						</div>
						<div>
							<h2>6.自由变卖阶段</h2>
							<span>根据融配资双方需要处置的抵押物进行人工评估及处置方案策划</span>
						</div>
						<div>
							<h2>8.自动扣款</h2>
							<span>处置款通过平台收取，自动按融配资双方协定的比例进行扣款和结算。还款结束并不代表委托处置结束，但不会再进行额外扣款，直至您委托平台的所有资产均以处置结束。</span>
						</div>
					</div>
					<img src="../../assets/images/web_h5/s_1.png" />
					<div class="img_say2">
						<div>
							<h2>1.达成融资，委托平台处置</h2>
							<span>在您与配资方达成合作后，上传《融配资合同》、《委托处置还款合同》以及《抵押物管理保全合同》</span>
						</div>
						<div>
							<h2>3.大数据价格采集分析</h2>
							<span>采集全网相关二手设备交易数据并进行最近成交价及趋势分析，得出合理的成交价</span>
						</div>
						<div>
							<h2>5.达成融资，委托平台处置</h2>
							<span>依照情况，确定竞价设备名录，并邀请高意向的目标用户参与线上竞价会</span>
						</div>
						<div>
							<h2>7.达成融资，委托平台处置</h2>
							<span>组织附近的回收商前往实地进行看货</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				src:this.imgBasUrl2+'rz_wx.png',
				rz_s_banner:this.imgBasUrl2+'rz_s_banner.png',
				rz_pc:this.imgBasUrl2+'rz_pc.png',
				rz_wx:this.imgBasUrl2+'rz_wx.png',
			}
		},
		methods: {
			enter(index) {
				if(index==1){
					this.src = this.rz_wx;
				}else {
					this.src = this.rz_pc;
				}
			},
		},
	};
</script>

<style>
	@import url('../../assets/css/web_h5/manage.css');
	@import url('../../assets/css/web_h5/dispole.css');
</style>
